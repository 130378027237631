<template>
  <div class="login-screen sys-screen">
    <div class="center-block">
      <div class="positioning fadeInDown animated">
        <div class="vertical-10p">
          <img
            src="../../../public/static/assets/img/logo.svg"
            class="center-block logo logo-login"
            alt="Zordon logo"
          >
          <div class="text-center">
            <h1 class="login">
              {{$t('login.changeTempPassword')}}
            </h1>
            <p class="login">
              {{$t('login.firstLoginPasswordChange')}}
            </p>
            <form @submit.prevent="onSubmitForm()">
              <input-type
                class="change-password-field"
                v-for="field in formFields"
                :key="field.name"
                v-model="model[field.name]"
                v-validate="{ rules: field.rules }"
                :data-vv-value-path="'value'"
                :data-vv-as="field.label2 ? field.label2 : $t(field.label)"
                :error-message="vErrors.first(field.name)"
                :label="$t(field.label)"
                :name="field.name"
                :required="field.rules.required"
                :type="'password'"
              />
              <button
                type="submit"
                :class="'btn btn-primary btn-flat ' + (isLoading ? 'disabled' : '')"
                :disabled="isLoading"
              >
                Zmień hasło
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Validator } from 'vee-validate'
import InputType from '../share/form/type/InputType'
import api from '../../api'
import Loader from '../share/Loader'
import Authenticate from './Authenticate'

export default {
  components: {
    InputType
  },
  mixins: [
    Loader,
    Authenticate
  ],
  data () {
    return {
      model: {
        oldPassword: '',
        password: '',
        password2: ''
      },
      formFields: [
        { name: 'oldPassword', label: 'login.tempPasswordInput', rules: { required: true } },
        { name: 'password', label: 'login.newPasswordInput', rules: { required: true, password_form: true, different_password: true } },
        { name: 'password2', label: 'login.repeatNewPasswordInput', rules: { required: true, repeated_password: true } }
      ],
      serverResponse: null,
      loading: ''
    }
  },
  created () {
    Validator.extend('repeated_password', {
      getMessage: () => this.$t('login.repeatedPasswordValidator'),
      validate: value => value === this.model.password
    })
    Validator.extend('password_form', {
      getMessage: () => this.$t('login.requiredPassworddValidator'),
      validate: value => (/[A-Z]+/.test(value) && /[0-9]+/.test(value) && /[!@#$%^&*()_+={}\\[\]:;"'<,>.?/-]+/.test(value))
    })
    Validator.extend('different_password', {
      getMessage: () => this.$t('login.newPasswordDifferentThanTemp'),
      validate: value => value !== this.model.oldPassword
    })
  },
  methods: {
    onSubmitForm () {
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      
      api.request('core', 'put', `users/${this.$store.state.base.user.uuid}/password`, this.createDataToSend())
        .then((response) => {
          this.toggleLoading()
          const data = response.data
          if (data.error) {
            this.getServerErrorMessage(data)
          } else {
            this.checkLogin(data.username, this.model.password)
          }
        })
        .catch(() => {
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
          this.serverResponse = {
            type: 'error',
            message: 'Błąd serwera'
          }
        })
    },
    createDataToSend () {
      return {
        oldPassword: this.model.oldPassword,
        password: this.model.password
      }
    },
    getServerErrorMessage (data) {
      this.serverResponse = {
        type: 'info',
        message: ''
      }
      const errorName = data.error.name
      if (errorName) {
        this.serverResponse.message = errorName === 'FormValidationError'
          ? 'Błędne dane w formularzu.'
          : errorName
      } else {
        this.serverResponse.message = data.error
      }
    }
  }
}
</script>

<style scoped>
html {
    position: relative;
    min-height: 100%;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #fff;
}
.change-password-field  {
  color: #fff;
}
</style>
